import { useState } from 'react'
import { MapContainer, TileLayer, LayersControl, Marker, Popup, FeatureGroup } from 'react-leaflet'
import { CssBaseline, ButtonGroup, Button } from "@mui/material";
import Control from "react-leaflet-custom-control";
import {
  Adjust as AdjustIcon
} from "@mui/icons-material";
import { useMapEvents } from 'react-leaflet/hooks'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './App.scss';
import MinimapControl from './minimap'

var LeafIcon = L.Icon.extend({
  options: {
    iconSize: [40, 40],
    iconAnchor: [22, 40],
    popupAnchor: [0, -40]
  }
});

var fishIcon = new LeafIcon({ iconUrl: './icon-blue.png' })
var foodIcon = new LeafIcon({ iconUrl: './icon-yellow.png' })
var kfoodIcon = new LeafIcon({ iconUrl: './icon-orange.png' })
var tourIcon = new LeafIcon({ iconUrl: './icon-green.png' })
var shoppingIcon = new LeafIcon({ iconUrl: './icon-red.png' })

function DrawMarker(props) {
  return (
    <Marker key={props.item.longitude} position={[props.item.latitude, props.item.longitude]} icon={props.icon} popup={<a href={props.item.glisk} target="_blank" rel="noreferrer">View in Google Map</a>}>
      <Popup>
        <h3>{props.item.name}</h3>
        {props.item.description}
        <br />
        <a href={props.item.glink} target="_blank" rel="noreferrer">View in Google Map</a>
      </Popup>
    </Marker>
  )
}

function App() {
  let info = require('./info.json');
  const [position, setPosition] = useState([-37.6870, 176.1654])

  const LocateMe = () => {
    const map = useMapEvents({
      locationfound(e) {
        setPosition([e.latitude, e.longitude])
      }
    })
      
    const onClick = () => {
      map.locate()
      map.flyTo(position, 11);
    };

    return (
      <>
        <CssBaseline />
        <Control position="topright">
          <ButtonGroup orientation="vertical" variant="contained">
            <Button
              onClick={() => onClick()}
              variant="contained"
            >
              <AdjustIcon />
            </Button>
          </ButtonGroup>
        </Control>
      </>
    )
  }

  return (
    <div className="leaflet-container">
      <MapContainer center={[-37.6870, 176.1654]} zoom={11} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MinimapControl position="topcenter" zoom="4" />
        <LocateMe />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Tour">
            <FeatureGroup>
              {info.tour.map(item => (
                <DrawMarker key={item.id} item={item} icon={tourIcon}></DrawMarker>
              ))}
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Food">
            <FeatureGroup>
              {info.food.map(item => (
                <DrawMarker key={item.id} item={item} icon={foodIcon}></DrawMarker>
              ))}
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="K-Food">
            <FeatureGroup>
              {info.kfood.map(item => (
                <DrawMarker key={item.id} item={item} icon={kfoodIcon}></DrawMarker>
              ))}
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Shopping">
            <FeatureGroup>
              {info.shopping.map(item => (
                <DrawMarker key={item.id} item={item} icon={shoppingIcon}></DrawMarker>
              ))}
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Fishing">
            <FeatureGroup>
              {info.fishingspot.map(item => (
                <DrawMarker key={item.id} item={item} icon={fishIcon}></DrawMarker>
              ))}
            </FeatureGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    </div>
  );
}

export default App;
